import React from 'react'
import './faq.sass'
import { Link } from 'gatsby'

const Faq = () => {
  return (
    <div id="faq" className="faqSection">
      <div className="presaleInnerWrap">
        <h2>FAQ</h2>
        <div className='faqContainer'>
          <div className="gotFishContainerLeft">
            <div className="innerFaq">
              <span>HOW TO BUY WITH SOL</span>
              <p>To buy on Solana, go to <a href="https://solscan.io/token/FishgChr4rMPf1BgnZgHN6hkP443uGTe1ZpMmMJ7gS2B">Birdeye</a> and connect your Phantom, Solflare or any other Solana network wallet. Swap your  SOL to purchase $GODLEN tokens.  them to qualify for the airdrop! We'll detect this and start tracking your airdrop points.</p>
            </div>
            <div className="innerFaq">
              <span>What is GODLENFISH?</span>
              <p>Another meme? Yes, but different breed one. GODLENFISH is a unique utility meme coin, inspired by the old saying that catching a golden fish brings luck and fulfils wishes.</p>
            </div>
          </div>
          <div className="gotFishContainerRight">
            <div className="innerFaq">
            <span>How to Stake?</span>
              <p>Want to lock in those enormously big GODLEN gains? Then lock up your GODLEN tokens, just go to <a href="https://utilities.godlenfish.com/solana/godlen/stake">Staking</a>.  Choose staking duration, enter desired token amount, connect your Phantom, Solflare or any other Solana network wallet and stake! Remember, pool is limited!</p>
            </div>
            <div className="innerFaq">
            <span>Utility meme coin?</span>
              <p>Yes, GODLEN team is preparing significant amount of <a href='https://godlenpaper.godlenfish.com/godlenpaper/usdgodlen-utility' target="_blank" rel="noreferrer">crypto tools</a> for both meme coin enthusiasts and developers. To add, GODLEN army will enjoy staking protols, PVP games and more... Still thinking to buy? </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
