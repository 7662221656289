import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import '../styles/common.sass'
import '../styles/reset.sass'
import Hero from '../sections/hero/Hero'
import About from '../sections/about/About'
import Badges from '../sections/badges/Badges'
import Godlenomics from '../sections/godlenomics/Godlenomics'
import Roadmap from '../sections/roadmap/Roadmap'
import Banner from '../sections/banner/Banner'
import Faq from '../sections/faq/Faq'
import Enterprise from '../sections/enterprise/Enterprise'
import TextRow from '../sections/textRow/TextRow'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="$GODLENFISH" />
      <div className="section">
        <Hero />
        <TextRow />
        <About />
        <Badges />
        <Enterprise />
        <Godlenomics />
        <Roadmap />
        <Faq />
        <Banner />
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export const Head = () => <Seo title="Majestic and will lead the way in the crypto sea" />

export default IndexPage
