import React from 'react'
import './enterprise.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Enterprise = () => {
  return (
  <div id="enterprise" className="enterpriseSection">
    <div className="enterpriseInnerWrap">
      <div className="enterpriseRow">
        <div className="enterpriseBlocksContainer">
          <span>Godlen Enterprise?</span>
          <h2>THE  ULTIMATE GODLEN UTILITY DApp FOR SOLANA DEGENERATES</h2>
          <a href="https://utilities.godlenfish.com/">Discover Tools</a>
        </div>
        <StaticImage
              src="../../images/enterprise.png"
              height={570}
              className="enterpriseImage"
              placeholder="blurred"
              layout="constrained"
              alt="The Godlenfish enterprise"
            />
      </div>
    </div>
  </div>
  )
}

export default Enterprise
