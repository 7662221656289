import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import './roadmap.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { isMobile } from 'react-device-detect'

const arrowRightIcon = <svg width="109" height="16" viewBox="0 0 109 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M108.707 8.70711C109.098 8.31658 109.098 7.68342 108.707 7.29289L102.343 0.928932C101.953 0.538408 101.319 0.538408 100.929 0.928932C100.538 1.31946 100.538 1.95262 100.929 2.34315L106.586 8L100.929 13.6569C100.538 14.0474 100.538 14.6805 100.929 15.0711C101.319 15.4616 101.953 15.4616 102.343 15.0711L108.707 8.70711ZM108 7H106.071V9H108V7ZM102.214 7H98.3571V9L102.214 9V7ZM94.5 7H90.6429V9H94.5V7ZM86.7857 7L82.9286 7V9H86.7857V7ZM79.0714 7H75.2143V9H79.0714V7ZM71.3571 7L67.5 7V9L71.3571 9V7ZM63.6429 7L59.7857 7V9H63.6429V7ZM55.9286 7L52.0714 7V9H55.9286V7ZM48.2143 7L44.3571 7V9H48.2143V7ZM40.5 7L36.6429 7V9H40.5V7ZM32.7857 7L28.9286 7V9L32.7857 9V7ZM25.0714 7L21.2143 7V9H25.0714V7ZM17.3572 7L13.5 7V9H17.3572V7ZM9.64288 7H5.78574V9H9.64288V7ZM1.9286 7H-7.62939e-06V9L1.9286 9V7ZM108.707 8.70711C109.098 8.31658 109.098 7.68342 108.707 7.29289L102.343 0.928932C101.953 0.538408 101.319 0.538408 100.929 0.928932C100.538 1.31946 100.538 1.95262 100.929 2.34315L106.586 8L100.929 13.6569C100.538 14.0474 100.538 14.6805 100.929 15.0711C101.319 15.4616 101.953 15.4616 102.343 15.0711L108.707 8.70711ZM108 7H106.071V9H108V7ZM102.214 7H98.3571V9L102.214 9V7ZM94.5 7H90.6429V9H94.5V7ZM86.7857 7L82.9286 7V9H86.7857V7ZM79.0714 7H75.2143V9H79.0714V7ZM71.3571 7L67.5 7V9L71.3571 9V7ZM63.6429 7L59.7857 7V9H63.6429V7ZM55.9286 7L52.0714 7V9H55.9286V7ZM48.2143 7L44.3571 7V9H48.2143V7ZM40.5 7L36.6429 7V9H40.5V7ZM32.7857 7L28.9286 7V9L32.7857 9V7ZM25.0714 7L21.2143 7V9H25.0714V7ZM17.3572 7L13.5 7V9H17.3572V7ZM9.64288 7H5.78574V9H9.64288V7ZM1.9286 7H-7.62939e-06V9L1.9286 9V7Z" fill="black"/>
</svg>

const Roadmap = () => {
  const widthRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [playAnimation, setPlayAnimation] = useState(false)

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true)
    }
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
  }, [])

  useLayoutEffect(() => {
    setWidth(widthRef.current.offsetWidth)
  })
  return (
  <div id="roadmap" ref={widthRef} className="roadmapSection">
    <div className="roadmapInnerWrap">
      <h2>Roadmap</h2>
      <div className="roadmapRow">
      <div className="roadmapBlocksWrap">
        <div className="roadmapBlocksContainer">
          <StaticImage
            src="../../images/roadmap-1.webp"
            className="roadmapImage"
            placeholder="blurred"
            width={416}
            layout="fixed"
            alt="The Godlenfish logo"
          />
            <div className='roadmapHeader'><p><span className='yellowColor'>2024 / April</span></p>{arrowRightIcon}</div>
            <div className='roadmapBody'>The God of luck creates $GODLEN to save crypto degens from their unfulfilled dreams.</div>
        </div>
        <div className="roadmapBlocksContainer">
        <StaticImage
            src="../../images/roadmap-2.webp"
            className="roadmapImage"
            placeholder="blurred"
            width={416}
            layout="fixed"
            alt="The Godlenfish logo"
          />
            <div className='roadmapHeader'><p><span className='yellowColor'>2024 / May</span></p>{arrowRightIcon}</div>
            <div className='roadmapBody'>$GODLEN becomes the guru of luck, guiding traders through the chaos of meme coins.</div>
        </div>
        <div className="roadmapBlocksContainer">
        <StaticImage
            src="../../images/roadmap-3.webp"
            className="roadmapImage"
            placeholder="blurred"
            width={416}
            layout="fixed"
            alt="The Godlenfish logo"
          />
            <div className='roadmapHeader'><p><span className='yellowColor'>2024 / Q2</span></p>{arrowRightIcon}</div>
            <div className='roadmapBody'>$GODLEN rides a tsunami of hype, leaving skeptics drowning in disbelief.</div>
        </div>
        <div className="roadmapBlocksContainer">
        <StaticImage
            src="../../images/roadmap-4.webp"
            className="roadmapImage"
            placeholder="blurred"
            width={416}
            layout="fixed"
            alt="The Godlenfish logo"
          />
            <div className='roadmapHeader'><p><span className='yellowColor'>2024 / Q3</span></p>{arrowRightIcon}</div>
            <div className='roadmapBody'>Luck of God lands $GODLEN on a major CEXes, spreading fortune to all its holders.</div>
        </div>
        <div className="roadmapBlocksContainer">
        <StaticImage
            src="../../images/roadmap-5.webp"
            className="roadmapImage"
            placeholder="blurred"
            width={416}
            layout="fixed"
            alt="The Godlenfish logo"
          />
            <div className='roadmapHeader'><p><span className='yellowColor'>2024 / Q3</span></p>{arrowRightIcon}</div>
            <div className='roadmapBody'>$GODLEN Fish expands to multiple blockchains, multiplying its luck.</div>
        </div>
        <div className="roadmapBlocksContainer">
        <StaticImage
            src="../../images/roadmap-6.webp"
            className="roadmapImage"
            placeholder="blurred"
            width={416}
            layout="fixed"
            alt="The Godlenfish logo"
          />
            <div className='roadmapHeader'><p><span className='yellowColor'>2024 / Q4</span></p>{arrowRightIcon}</div>
            <div className='roadmapBody'>Godlen Fish outgrows the meme coin ocean, proving that when you're golden, size doesn't matter.</div>
        </div>
      </div>
      </div>
      <a className="buttonExplore" target='_blank' href='https://godlenfish.gitbook.io/godlenpaper' rel="noreferrer">Explore full master plan</a>
    </div>
    <div className='roadmapBgOverlay'></div>
  </div>
  )
}

export default Roadmap
