import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import './about.sass'
import { StaticImage } from 'gatsby-plugin-image'

const About = () => {
  const widthRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [playAnimation, setPlayAnimation] = useState(false)

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true)
    }
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
  }, [])

  useLayoutEffect(() => {
    setWidth(widthRef.current.offsetWidth)
  })
  return (
  <div id="about" ref={widthRef} className="aboutSection">
    <div className="aboutInnerWrap">
      <div className="aboutRow">
      <div className="aboutBlocksContainer">
          <h2>About</h2>
          <p>GODLENFISH is a meme surrounded by luck and good vibez only. This not ordinary aquarium dweller, its the golden ruler of memes that will lead the digital ocean and bring luck to its all hodlers.</p>
          <p>It's time to catch your GODLENFISH and say goodbye to those nights of staring at your empty wallet, crying into your pillow. $GODLEN isn't showing an ounce of mercy—it's time to either ride the wave or end up as fish food.</p>
        </div>
        <StaticImage
              src="../../images/godlenfish-coat.png"
              height={800}
              className="aboutFish"
              placeholder="blurred"
              layout="fixed"
              alt="The Godlenfish logo"
            />
      </div>
    </div>
  </div>
  )
}

export default About
