import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import './banner.sass'
import { StaticImage } from 'gatsby-plugin-image'
import { isMobile } from 'react-device-detect'

const arrowRightIcon = <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15.7082" cy="15.2918" r="15.1664" fill="#FFE600" stroke="black" strokeWidth="0.250685"/>
<circle cx="15.7082" cy="15.2918" r="13.6623" fill="#F6A12E" stroke="black" strokeWidth="0.250685"/>
<path d="M21.202 15.012L13.524 21.944L11.69 19.8613L16.7879 15.1364L11.4413 10.5047L13.4308 8.29767L21.202 15.012Z" fill="#260900"/>
</svg>

const Banner = () => {
  const widthRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [playAnimation, setPlayAnimation] = useState(false)

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true)
    }
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
  }, [])

  useLayoutEffect(() => {
    setWidth(widthRef.current.offsetWidth)
  })
  return (
  <div id="about" ref={widthRef} className="bannerSection">
      <div className="bannerRow">
      <div className="bannerBlocksContainer">
       { isMobile
         ? <StaticImage
              src="../../images/godlen-smoke-mobile.png"
              className="bannerFish"
              placeholder="blurred"
              height={445}
              layout="fixed"
              alt="The Godlenfish logo"
            />
         : <StaticImage
            src="../../images/godlen-smoke.png"
            className="bannerFish"
            placeholder="blurred"
            height={445}
            layout="fixed"
            alt="The Godlenfish logo"
          />}
        <div className='bannerTextContainer'>
          <p>Catch your</p>
          <h2>GODLENFISH</h2>
          <p>now</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Banner
