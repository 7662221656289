import React from 'react'
import './textRow.sass'

const TextRow = () => {
  return (
  <div className="textRowSection">
    <div className='sectionas'>
      <div className="container-text-row">
        <div className='textRowText'>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
        </div>
        <div className='textRowText'>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
          <span>$GODLEN</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TextRow
